<template>
<div>
    <affiliate-phone-modal ref="affiliatePhoneModal"></affiliate-phone-modal>
    <confirmation ref="Confirmation"></confirmation>
      <datatable-heading
        :hasTitleSection="false"
        :changePageSize="changePageSize"
        :searchChange="_.debounce(searchChange, 500)"
        :from="from"
        :to="to"
        :total="total"
        :perPage="perPage"
        :hasPageSizes="false"
        :titleAndButton="true">
        <template v-slot:button>
          <div class="title-and-right-element">
            <PageTitle :title="'Phone'" :identifier="'Affiliate #'+ affiliateID"></PageTitle>
            <div>
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="lg"
              class="top-right-button mr-2"
              @click="addNewAffiliatePhone()">
              Add New
            </b-button>
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="md"
              style="font-size: 18px"
              class="simple-icon-trash"
              v-show="isTrashVisible"
              @click="deleteItems()"
            ></b-button>
            </div>
          </div>
        </template>
      </datatable-heading>
      <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
        <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="affiliatePhoneID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded">
              <template slot="phoneNumber" slot-scope="props">
              <div @contextmenu="rightClicked(props.rowData, '', $event)">
                <div>
                  <p class="text-muted">{{ props.rowData.phoneNumber | VMask(phoneMask) }}</p>
                </div>
              </div>
            </template>
            </vuetable>
          </b-card>
          <vuetable-pagination ref="pagination"
            class="mt-4"
            @vuetable-pagination:change-page="onChangePage"/>
        </b-colxx>
      </b-row>
      </b-overlay>
      <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit/view')">
        <i class="simple-icon-docs" />
        <span>Edit/View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('delete')">
        <i class="simple-icon-trash" />
        <span>Delete</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<style scoped>
  label {
    color: #676461;
    font-weight: bold;
  }
</style>
<script>
import { BeaconapiUrl } from '../../../constants/config'
import confirmation from '../../../components/Common/Confirmation.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import AffiliateMixin from '../../../mixins/AffiliateMixin.vue'
import { validationMixin } from 'vuelidate'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import PhoneMixin from '../../../mixins/PhoneMixin.vue'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'
import AffiliatePhoneModal from '../../../components/Modals/AffiliatePhoneModal.vue'
import { mapMutations, mapActions } from 'vuex'

export default {
  props: ['affiliateID', 'affiliatePhoneID'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    PageTitle: ParentEntityPageTitle,
    'affiliate-phone-modal': AffiliatePhoneModal
  },
  mixins: [validationMixin, AffiliateMixin, PhoneMixin],
  data () {
    var _idfield = 'affiliatePhoneID'
    return {
      apiBase: `${BeaconapiUrl}/api/affiliate/${this.affiliateID}/phone/table`,
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      filters: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '5%'
        },
        {
          name: '__slot:phoneNumber',
          title: 'Phone Number',
          titleClass: '',
          dataClass: 'text-muted',
          width: '45%'
        },
        {
          name: 'phoneNumberExtension',
          sortField: 'phoneNumberExtension',
          title: 'Extension',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%'
        },
        {
          name: 'phoneNumberTypeDesc',
          sortField: 'phoneNumberTypeDesc',
          title: 'Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '25%'
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    onRowClass (dataItem, index) {},
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.$refs.vuetable.selectedTo = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.$refs.vuetable.refresh()
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    rowClicked (dataItem, event) {
      // check to see if we are clicking on the readMore/readLess link, if so do not select or unselect the row.
      if (!(event.path !== null && event.path[0] !== null && event.path[0].id !== null && (event.path[0].id.indexOf('readMore') === 0 || event.path[0].id.indexOf('readLess') === 0))) {
        const itemId = dataItem[this.$data.idfield]

        if (this.$refs.vuetable.selectedTo.includes(itemId)) {
          this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
            (x) => x !== itemId
          )
        } else {
          this.$refs.vuetable.selectedTo.push(itemId)
        }
        this.selectedItems = this.$refs.vuetable.selectedTo
      }
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    // delete one or multiple items
    async deleteItems (record) {
      let message = ''; let title = 'Delete Affiliate Phone'
      if (record) {
        message = `Are you sure you want to delete ${record}?`
      } else {
        if (this.$refs.vuetable.selectedTo.length > 1) {
          title = 'Delete Affiliate Phones'
          message = 'Are you sure you want to delete these records?'
        } else {
          const matchRecord = this.$refs.vuetable.tableData.find(({ affiliatePhoneID }) => affiliatePhoneID === this.$refs.vuetable.selectedTo[0])
          message = `Are you sure you want to delete ${matchRecord.phoneNumber}?`
        }
      }
      this.$refs.Confirmation.show({
        title: title,
        message: message,
        okButton: 'Delete'
      }).then(async result => {
        if (result) {
          // loop through all selected items from the grid
          this.$refs.vuetable.selectedTo.forEach(async item => {
            this.setInfoMessage('')
            // dispatch vuex action to delete each selected phone
            await this.deleteAffiliatePhone(item)
            if (this.statusCode === 200) {
              this.refreshVueTable()
            }
          })
        }
      })
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    cellclicked (dataitem, field, event) {},
    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'delete': {
          this.deleteItems(rightClickedItem.phoneNumber)
          break
        }
        case 'edit/view': {
          if (this.$refs.vuetable.selectedTo.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only edit one item at a time',
              okButton: 'Cancel',
              exitFunction: (e) => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.$refs.vuetable.refresh()
                }
              }
            })
          } else {
            this.$refs.affiliatePhoneModal.show({
              title: `Edit/View For Phone ID: ${this.$refs.vuetable.selectedTo[0]}`,
              affiliatePhoneID: this.$refs.vuetable.selectedTo[0],
              exitFunction: () => {
                this.refreshVueTable()
              }
            })
          }
          break
        }
        default:
          break
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    searchChange (val) {
      this.search = val
      this.$refs.vuetable.refresh()
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    async addNewAffiliatePhone () {
      this.$refs.affiliatePhoneModal.show({
        title: 'Add New Affiliate Phone',
        affiliateID: this.affiliateID,
        exitFunction: () => {
          this.refreshVueTable()
        }
      })
    }
  },
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    }
  }
}
</script>
