<template>
  <b-modal id="modalbasic" size="lg" ref="modalbasic" @hide="close()" v-model="isVisible" :title="this.title"
    :no-close-on-backdrop="true" @ok='close()' @cancel='close()'>
    <messages />
    <b-form>
      <b-row>
        <b-col>
          <b-form-group label="Phone Number:" label-for="nested-phoneNumber">
            <input
              type="tel"
              id="nested-phoneNumber"
              class="form-control"
              :class="{ 'is-invalid' : $v.affiliatePhone.phoneNumber.$error}"
              v-model.number="$v.affiliatePhone.phoneNumber.$model"
              @input="$v.affiliatePhone.phoneNumber.$touch"
              v-mask='phoneMask'
              placeholder="Phone Number"
            />
            <div
              class="text-danger"
              :state="!$v.affiliatePhone.phoneNumber.$error"
              v-if="!$v.affiliatePhone.phoneNumber.required"
            >
              Field is required
            </div>
              <div class="text-danger"
              :state="!$v.affiliatePhone.phoneNumber.$error"
              v-if="!$v.affiliatePhone.phoneNumber.minLength">Must have 10 digits.</div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Ext." label-for="nested-phoneNumberExtension">
            <b-form-input
              id="nested-phoneNumberExtension"
              v-mask="extMask"
              class="col-sm-10"
              v-model.trim="$v.affiliatePhone.phoneNumberExtension.$model"
              :state="!$v.affiliatePhone.phoneNumberExtension.$error"
              placeholder="Ext."
            ></b-form-input>
              <div class="text-danger"
              :state="!$v.affiliatePhone.phoneNumberExtension.$error"
              v-if="!$v.affiliatePhone.phoneNumberExtension.maxLength">Must have at most {{$v.affiliatePhone.phoneNumberExtension.$params.maxLength.max}} digits.</div>
              <div class="text-danger"
              :state="!$v.affiliatePhone.phoneNumberExtension.$error"
              v-if="!$v.affiliatePhone.phoneNumberExtension.numeric">Must be a number.</div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label>Phone Number Type</label>
            <b-form-select
              v-model.trim="$v.affiliatePhone.phoneNumberType.$model"
              :state="!$v.affiliatePhone.phoneNumberType.$error"
              :options="phoneTypes"
              value-field="phoneNumberTypeID"
              text-field="phoneNumberTypeDesc"
              plain
              @input="$v.affiliatePhone.$touch"></b-form-select>
            <div class="text-danger" :state="!$v.affiliatePhone.phoneNumberType.$error" v-if="!$v.affiliatePhone.phoneNumberType.required">Field is required</div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <template #modal-footer="{ ok }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="ok">
        Cancel
      </b-button>
      <b-button
        :disabled="$v.$invalid"
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveAffiliatePhone()">
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>
<script>
import AffiliateMixin from '../../mixins/AffiliateMixin.vue'
import { validationMixin } from 'vuelidate'
import { mapMutations } from 'vuex'
import PhoneMixin from '../../mixins/PhoneMixin.vue'

import ModalMessages from '../../containers/navs/ModalMessages.vue'

const {
  required,
  minLength,
  maxLength,
  numeric
} = require('vuelidate/lib/validators')

export default {
  components: {
    messages: ModalMessages
  },

  mixins: [validationMixin, AffiliateMixin, PhoneMixin],

  validations: {
    affiliatePhone: {
      phoneNumber: {
        required,
        minLength: minLength(14)
      },
      phoneNumberType: {
        required
      },
      phoneNumberExtension: {
        maxLength: maxLength(6),
        numeric
      }
    }
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    affiliatePhoneID: 0
  }),

  async created () {
    await this.getPhoneTypes()
  },

  methods: {
    ...mapMutations([
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      if (opts.affiliatePhoneID) {
        this.affiliatePhoneID = (opts.affiliatePhoneID)
        await this.getAffiliatePhoneByID(this.affiliatePhoneID)
      }

      if (opts.affiliateID) {
        this.initNewPhone(opts.affiliateID)
      }

      this.isVisible = true
    },

    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.affiliatePhoneID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async saveAffiliatePhone () {
      if (this.affiliatePhone.phoneNumberExtension === '') {
        this.affiliatePhone.phoneNumberExtension = null
      }
      if (this.affiliatePhoneID) {
        await this.updateAffiliatePhone(this.affiliatePhone)
      } else {
        await this.addAffiliatePhone(this.affiliatePhone)
      }
      this.close()
    },
    initNewPhone (affiliateID) {
      this.affiliatePhone = {
        affiliateID: affiliateID,
        phoneNumber: '',
        phoneNumberExtension: null,
        phoneNumberType: null
      }
    }
  }
}
</script>
